import React from "react";
import ReactDOM from "react-dom";
import * as tf from '@tensorflow/tfjs';
//import * as tfn from '@tensorflow/tfjs-node';
import {loadGraphModel} from '@tensorflow/tfjs-converter';
import "./styles.css";
tf.setBackend('webgl');

const threshold = 0.4;

async function load_model() {

    //const model = await loadGraphModel("http://127.0.0.1:8080/model.json");    
    //const model = await loadGraphModel("http://192.168.0.7:8080/model.json");
    //const model = await loadGraphModel("http://192.168.0.7:8080/mb3-centernet.json");

    //const model = await loadGraphModel("https://wcs.inspektlabs.com/models/guidance/model.json");
    //const model = await loadGraphModel("https://wcs.inspektlabs.com/models/temp/ssd_mbv2_tf2/model.json");
    //const model = await loadGraphModel("https://wcs.inspektlabs.com/models/temp/ssd_mbv2_tf2_uint8/model.json");
    //const model = await loadGraphModel("https://wcs.inspektlabs.com/models/temp/ssd_mbv2/model.json");
    //const model = await loadGraphModel("https://wcs.inspektlabs.com/models/temp/ssdlite_mbv2/model.json");
    const model = await loadGraphModel("https://wcs.inspektlabs.com/models/temp/kash_ssd_mbv2_trim_uint8/model.json");

    return model;
  }

/*
let classesDir = {
    1: {
        name: 'Kangaroo',
        id: 1,
    },
    2: {
        name: 'Other',
        id: 2,
    }
}
*/

/*
CLASS_NAMES =  ["BG", "front_bumper", "back_bumper", "front_door", 
"back_door", "dicky", "front_glass", "back_glass", "fender", "qtr_panel", 
"hood", "window_glass", "headlight", "taillight", "wheel", "side_view_mirror"]


let classesDir = {
  0: {
      name: 'BG',
      id: 0,
  },
  1: {
      name: 'front_bumper',
      id: 1,
  },
  2: {
      name: 'back_bumper',
      id: 2,
  },
  3: {
      name: 'front_door',
      id: 3,
  },
  4: {
      name: 'back_door',
      id: 4,
  },
  5: {
      name: 'dicky',
      id: 5,
  },
  6: {
      name: 'front_glass',
      id: 6,
  },
  7: {
      name: 'back_glass',
      id: 7,
  },
  8: {
     name: 'fender',
      id: 8,
  },
  9: {
      name: 'qtr_panel',
      id: 9,
  },
  10: {
      name: 'hood',
      id: 10,
  },
  11: {
      name: 'window_glass',
      id: 11,
  },
  12: {
      name: 'headlight',
      id: 12,
  },
  13: {
      name: 'taillight',
      id: 13,
  },
  14: {
      name: 'wheel',
      id: 14,
  },
  15: {
      name: 'side_view_mirror',
      id: 15,
  },
}
*/

/*
let classesDir = {
  1: {
      name: 'front_glass',
      id: 1,
  },
  2: {
      name: 'back_glass',
      id:2,
  },
  3: {
      name: 'window_glass',
      id: 3,
  },
  4: {
      name: 'headlight',
      id: 4,
  },
  5: {
      name: 'taillight',
      id: 5,
  },
  6: {
      name: 'wheel',
      id: 6,
  },
}
*/

let classesDir = {
  0: {
      name: 'BG',
      id: 0,
  },
  1: {
      name: 'person',
      id: 1,
  },
  2: {
      name: 'bicycle',
      id: 2,
  },
  3: {
      name: 'car',
      id: 3,
  },
  4: {
      name: 'motorcycle',
      id: 4,
  },
  5: {
      name: 'airplane',
      id: 5,
  },
  6: {
      name: 'bus',
      id: 6,
  },
  7: {
      name: 'train',
      id: 7,
  },
  8: {
     name: 'truck',
      id: 8,
  },
  9: {
      name: 'boat',
      id: 9,
  },
  10: {
      name: 'traffic light',
      id: 10,
  },
  11: {
      name: 'fire hydrant',
      id: 11,
  },
  12: {
      name: 'stop sign',
      id: 12,
  },
  13: {
      name: 'parking meter',
      id: 13,
  },
  14: {
      name: 'bench',
      id: 14,
  },
  15: {
      name: 'bird',
      id: 15,
  },
}


class App extends React.Component {
  videoRef = React.createRef();
  canvasRef = React.createRef();


  componentDidMount() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const webCamPromise = navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            width: { exact: 128 },
            height: { exact: 128 },
            //width: { min: 16, max: 16 },
            //height: { min: 9, max: 9 },
            facingMode: "environment",
            //frameRate: { ideal: 5, max: 15 },
          }
        })
        .then(stream => {
          window.stream = stream;
          this.videoRef.current.srcObject = stream;
          return new Promise((resolve, reject) => {
            this.videoRef.current.onloadedmetadata = () => {
              resolve();
            };
          });
        });
      const modelPromise = load_model();

      Promise.all([modelPromise, webCamPromise])
        .then(values => {
          this.detectFrame(this.videoRef.current, values[0]);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

    detectFrame = (video, model) => {
        var t0 = performance.now()     
        //console.time("frameTime1");
        //console.time("frameTime2");
        tf.engine().startScope();
        let processed = this.process_input(video);
        //let predictions = model.execute(processed);
        //model.executeAsync(processed).then(predictions => {
        model.executeAsync(this.process_input(video)).then(predictions => {
          var t1 = performance.now()            
          var t = t1-t0;
          //console.log("Time: " + t);
          //this.renderPredictions(predictions, video);
          //console.time("f1");
          this.renderPredictions(predictions, t);
          //console.timeEnd("f1");
          requestAnimationFrame(() => {
            //console.timeEnd("frameTime1");
            this.detectFrame(video, model);
          });
          tf.engine().endScope();
          //console.timeEnd("frameTime2");                 
          //console.log("-------");
      });
  };

  process_input(video_frame){
    //console.time("f2");
    const tfimg = tf.browser.fromPixels(video_frame);
    //console.log(tfimg.dtype)
    //console.log(tfimg.shape)
    const expandedimg = tfimg.expandDims();//.toFloat();
    //console.timeEnd("f2");
    return expandedimg;    
  };

  buildDetectedObjects(scores, threshold, boxes, classes, classesDir) {
    const detectionObjects = []
    var video_frame = document.getElementById('frame');

    scores[0].forEach((score, i) => {
      if (score > threshold) {
        const bbox = [];
        const minY = boxes[0][i][0] * video_frame.offsetHeight;
        const minX = boxes[0][i][1] * video_frame.offsetWidth;
        const maxY = boxes[0][i][2] * video_frame.offsetHeight;
        const maxX = boxes[0][i][3] * video_frame.offsetWidth;
        bbox[0] = minX;
        bbox[1] = minY;
        bbox[2] = maxX - minX;
        bbox[3] = maxY - minY;
        detectionObjects.push({
          class: classes[i],
          label: classesDir[classes[i]].name,
          score: score.toFixed(4),
          bbox: bbox
        })
      }
    })
    return detectionObjects
  }
  renderWarningString(t) {

    // Get the canvas and set the font
    const ctx = this.canvasRef.current.getContext("2d");
    const font = "16px sans-serif";
    ctx.font = font;

    // Draw and Fill a Rectangle that will contain the text
    ctx.fillStyle = "#00FF00";
    const textHeight = parseInt(font, 10);
    ctx.fillRect(0, ctx.canvas.height - textHeight - 10, ctx.canvas.width, textHeight + 30);

    // Fill the Rectangle with the Text
    ctx.fillStyle = "#000000";
    ctx.fillText(t, 0, ctx.canvas.height - 15);
}

  //renderPredictions = predictions => {
  renderPredictions = (predictions,t) => {
    const ctx = this.canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    //console.log("Time: " + t);

    // Font options.
    const font = "16px sans-serif";
    ctx.font = font;
    ctx.textBaseline = "top";

    console.log("TIME--------,", t);
    this.renderWarningString(t);
    //console.log("Check")
    console.log(predictions)
    console.log(typeof(predictions))

    //Getting predictions
    //const boxes = predictions[4].arraySync();
    const boxes = predictions[0].arraySync();
    console.log(boxes)
    //const scores = predictions[5].arraySync();
    const scores = predictions[1].arraySync();
    console.log(scores)
    //console.log(predictions[2].arraySync());
    //console.log(predictions[3].arraySync());
    //console.log(predictions[4].arraySync());
    //console.log(predictions[5].arraySync());
    //console.log(predictions[6].arraySync());
    //console.log(predictions[7].arraySync());
    //const classes = predictions[6].dataSync();
    //const classes = predictions[2].dataSync();
    //console.log(classes)
  
    //const gg = predictions[3].dataSync();
    //console.log(gg)
    //const detections = this.buildDetectedObjects(scores, threshold, boxes, classes, classesDir);
    
    //console.log("--------------------------------------------------------")

    /*
    let  detections;

    detections.forEach(item => {
      const x = item['bbox'][0];
      const y = item['bbox'][1];
      const width = item['bbox'][2];
      const height = item['bbox'][3];

      // Draw the bounding box.
      ctx.strokeStyle = "#00FFFF";
      ctx.lineWidth = 4;
      ctx.strokeRect(x, y, width, height);

      // Draw the label background.
      ctx.fillStyle = "#00FFFF";
      const textWidth = ctx.measureText(item["label"] + " " + (100 * item["score"]).toFixed(2) + "%  Time:" + t.toFixed(2)).width;
      const textHeight = parseInt(font, 10); // base 10
      ctx.fillRect(x, y, textWidth + 4, textHeight + 4);
    });

    detections.forEach(item => {
      const x = item['bbox'][0];
      const y = item['bbox'][1];

      // Draw the text last to ensure it's on top.
      ctx.fillStyle = "#000000";
      ctx.fillText(item["label"] + " " + (100*item["score"]).toFixed(2) + "% Time:" + t.toFixed(2), x, y);
    });
    */
  };

  render() {
    return (
      <div>
        <h1>Car Part Detection</h1>
        <video
          style={{height: '600px', width: "500px"}}
          className="size"
          autoPlay
          playsInline
          muted
          ref={this.videoRef}
          width="600"
          height="500"
          id="frame"
        />
        <canvas
          className="size"
          ref={this.canvasRef}
          width="600"
          height="500"
        />
      </div>
    );
  }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);